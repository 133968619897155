<template>
  <div class="privacidad-box">
    <van-checkbox v-model="checked" @change="changeHandle">
      <template #icon="props">
        <span :class="{'is-checked':props.checked}" class="checkbox-item"></span>
      </template>
    </van-checkbox>
    <span class="remark">{{ $t('login.agreePrivacy')[0] }} </span>
    <span class="privacidad-item" @click="showPrivacidad">{{ $t('login.agreePrivacy')[1] }} </span>
  </div>
</template>

<script>

export default {
 
  data() {
    return {
      checked: true
    }
  },
  methods: {
    showPrivacidad() {
      this.$router.push({
        path: '/privacyAgreement'
      })
    },
    changeHandle() {
      this.$emit('changeHandle', this.checked)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.van-checkbox){
  position: absolute;
  top: 50%;
  margin-top: -18px;
}
.checkbox-item{
  position: relative;
  display: block;
  width: 36px;
  height: 36px;
  background: url('~@/assets/images/public/no-checked-icon.png') no-repeat center center;
  background-size: 100%;
}

.is-checked.checkbox-item{
  background: url('~@/assets/images/public/checked-icon.png') no-repeat center center;
  background-size: 100%;
}
:deep(.van-checkbox__icon){
  height: auto;
}
.remark{
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #999999;
  padding-left: 48px;
  margin-bottom: 10px;
}
.privacidad-item{
  font-size: 26px;
  color: #005FE9FF;
  width: 100%;
  padding-left: 48px;
  text-decoration: underline;
}
.privacidad-box{
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
</style>

import Cookies from 'js-cookie';
const TokenKey = 'easypresto_token';
const UserKey = 'userInfo';
export function getToken() {
    return Cookies.get(TokenKey);
}
export function setToken(token) {
    return Cookies.set(TokenKey, token);
}
export function removeToken() {
    return Cookies.remove(TokenKey);
}
export function getUser() {
    return sessionStorage.getItem(UserKey);
}
export function setUser(user) {
    return sessionStorage.setItem(UserKey, user);
}
export function removeUser() {
    return sessionStorage.removeitem(UserKey);
}

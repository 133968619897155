<template>
  <div>
  <base-template :show-head="false" @headerBack="headerBack">
    <LoginTop class="login-top" />
    <!-- <div class="use-phone-box">
      <div class="item-title">{{ $t('login.phoneNumber') }}</div>
      <div class="item-mobile">{{ dataForm.mobile }}</div>
    </div> -->
    <div class="login-passwoord-info">
      <div class="item-title">{{ $t('login.inputValidCode') }}</div>
      <div class="password-opr">
        <van-field
          v-model="dataForm.verify_code"
          center
          class="password-input"
          maxlength="4"
          type="digit"
          @blur="blurFun('sms_duration')" 
          @focus="focusFun('sms_num')"
        />
        <send-code-button ref="sendCodeButton" v-model="dataForm.code" class="send-btn" module-type="sms_login" unique-key="otpLogin" :mobile="dataForm.mobile" />
      </div>
      <p class="get-voice-code"><span @click="getVoiceCode">{{ $t('login.sendVoice') }}</span></p>
      <privacidad ref="privacidad" class="privacidad agree" @changeHandle="changeHandle" />
      <submit-btn ref="submit-btn" :sub-text="$t('login.loginText')" :submit-flag="submitFlag" @submit="validateCode" />
      <p class="login-for-sms"><span @click="goSMSLogin">{{ $t('login.passWordLogin') }}</span></p>
    </div>
  </base-template>
  
  </div>
</template>

<script>
import LoginTop from '@/views/login/components/LoginTop'
import SendCodeButton from '@/components/SendCodeButton'
import Privacidad from '@/views/register/components/Privacidad'
import toRouter from "@/mixins/toRouter";
import { codeLogin ,loginPoint} from '@/api/axios/index'
import { setToken, setUser } from '@/utils/auth'
import { getnewtime, getinterval } from '@/utils'
export default {
  name:'OtpLogin',
  mixins: [toRouter],
  components: { LoginTop, SendCodeButton, Privacidad },
  data() {
    return {
      isAgree: true,
      // submitFlag: false,
      isSend: sessionStorage.getItem('isSend'),
      dataForm: {
        mobile: this.$route.query.mobile,
        login_status: '2',
        code_type: '',
        verify_code: ''
      }
    }
  },
  computed: {
    submitFlag() {
      console.log(this.dataForm.verify_code.length,'this.dataForm.verify_code.length');
      return this.dataForm.verify_code.length > 3 && this.isAgree
    }
  },
  watch:{
    'dataForm.verify_code':{
      handler(){
        this.updatecountfun('verify_code','sms_updatecount')
      }
    }
  },
  methods: {

    // 获取焦点
    focusFun(nametwo) {
      this.start = getnewtime(); // 初始化时间
      this.$store.state.settings.point[nametwo]++
    },

    // 失去焦点 - 计算时长
    blurFun(name) {
      //  计算时长
      this.$store.state.settings.point[name] += getinterval(
        getnewtime(),
        this.start
      );
    
    
    },
    // 次数
    updatecountfun(name,nametwo) {
      // 初始化不要走
      if (!this.dataForm[name]) {
        this.$store.state.settings.point[nametwo]++;
      }
    },


    // 隐私协议状态
    changeHandle(val) {
      this.isAgree = val
    },
    // 校验code
    async validateCode() {
      if (!this.dataForm.verify_code.length) return
      // const valid = await this.$refs.sendCodeButton.checkNewsCode(this.dataForm.code)
      this.codeLogin()
    },

    // 验证码登录
    async codeLogin() {
      const params = {
        mobile_phone_number: this.dataForm.mobile,
        Umusango_Ibumba: '2',
        Infungulo_yankama: this.dataForm.verify_code
      }
      params.Umusango_Ibumba = this.$refs.sendCodeButton.codeType + '' === '1' ? '2' : '3'
      const { data } = await codeLogin(params) || {}
      setToken(data?.token)
      setUser(data)
      sessionStorage.setItem('userInfo', JSON.stringify(data || {}))
      // await loginPoint(this.$store.state.settings.point)
      this.$H5Object.loginSuccess(data)
      
    },

    // 获取语音验证
    getVoiceCode() {
      this.$refs.sendCodeButton.sendSms(2)
    },

    // 密码登录
    goSMSLogin() {
      this.$router.push({
        path: '/passLogin',
        query: {
          mobile: this.dataForm.mobile
        }
      })
    },
    headerBack() {
      this.$router.push({
        path: '/passLogin',
        query: {
          mobile: this.dataForm.mobile
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  padding-left: 64px;
  padding-right: 64px;
}
.login-passwoord-info{
  padding-top: 86px;
}
:deep(.btn-box){
  padding-top: 0;
  margin-bottom: 0;
}

:deep(.phone-input),
:deep(.password-input){
  height: 128px;
  background: #F7F7F7FF;
  border-radius: 32px;
  border: none;
}
:deep(.password-input){
  margin-bottom: 32px;
}
:deep(.btn-box .submit-btn){
  width: auto;
  margin: 0;
}

:deep(.phone-input .van-field__label){
  position: relative;
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #0B0320;
  padding: 34px;
  width: auto;
}
:deep(.phone-input .van-field__label::after){
  content: '';
  position: absolute;
  height: 56px;
  border: 2px solid #E7E7E7;
  top: 50%;
  margin-top: -28px;
  right: 0;
}

:deep(.phone-input .van-field__control),
:deep(.password-input .van-field__control){
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #0B0320;
  padding-left: 26px;
  letter-spacing: 3px;
}
:deep(.phone-input .van-field__control:disabled){
  -webkit-text-fill-color:#323233;
  color: #323233;
}

.password-opr{
  position: relative;
}

.hide-pass{
  .eye{
    background: url('~@/assets/images/login/hide-pass.png') no-repeat center center;
    background-size: 100%;
  }
  :deep(.van-field__control){
    -webkit-text-security: disc;
  }
}
.show-pass{
  .eye{
    background: url('~@/assets/images/login/show-pass.png') no-repeat center center;
    background-size: 100%;
  }
}

.get-voice-code{
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #005FE9;
  line-height: 30px;
  margin-bottom: 106px;
  text-align: right;
}
.login-for-sms{
  font-size: 32px;
  font-family: Roboto-Medium, Roboto;
  font-weight: 500;
  color: #1C1D21;
  line-height: 40px;
  text-align: center;
  margin-top: 48px;
}
:deep(.send-code-btn){
  height: 128px;
  line-height: 128px;
  margin-top: 0 !important;;
  margin-left: 30px;
}

.item-title{
  font-size: 32px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #1A1A1A;
  line-height: 48px;
  margin-bottom: 34px;
}

.use-phone-box{
  margin-bottom: 50px;
  .item-mobile{
    height: 128px;
    line-height: 128px;
    background: #F7F7F7FF;
    border-radius: 32px;
    border: none;
    font-size: 28px;
    font-weight: 400;
    color: #1F1F39;
    text-indent: 48px;
  }
}

:deep(.code-input){
  height: 128px;
  background: #F7F7F7FF;
  border-radius: 32px;
  border: none;
  opacity: 1;
  font-size: 28px;
  font-weight: 400;
  color: #1F1F39;
  text-indent: 48px;
}

.send-btn{
  position: absolute;
  top: 0;
  right: 0;
}

.agree{
   margin-bottom: 24px;
}
:deep(.code-input-box){
  display: none;
}
:deep(.main-content){
  background: #fff;
  .child-title{
    display: none;
  }
}
</style>
